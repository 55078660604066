<template>
  <div>
    <v-card class="elevation-1">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="7" class="mb-0 pb-0">
            <v-autocomplete
              v-model="product"
              outlined
              dense
              label="Product"
              item-text="product_name"
              item-value="id"
              :items="products"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="3" class="mb-0 pb-0">
            <v-text-field
              v-model="quantity"
              placeholder="0"
              label="Qty"
              outlined
              dense
              hide-details
              class="mb-2"
              @keypress.enter="addItem"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2" class="mb-0 pb-0">
            <v-btn block color="primary" @click="addItem">Add item</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="elevation-1 mt-2">
      <v-card-title class="pb-0 mb-0">
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="searchTable"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        v-model="selectedData"
        hide-default-footer
        dense
      >
        <template #[`item.action`]="{ item }">
          <v-btn
            x-small
            icon
            color="error"
            @click="deleteItem(item)"
          >
            <v-icon>{{ icon.delete }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.srp`]="{ item }">
          <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.srp) }}</span>
        </template>
      </v-data-table>

      <snackbar
        v-if="responseMessage.length > 0"
        :show="responseMessage.length > 0"
        :text="responseMessage"
        :color="responseMessageStatus"
        @close="responseMessage=''"
      />
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  props: ['customerType', 'basket'],

  setup(props, { emit }) {
    const selectedData = ref([])
    const search = ref('')
    const status = ref('available')
    const defaulHeader = ref([
      { text: '', align: 'middle', value: 'action', sortable: false, width: '20' },
      { text: 'Product Code', align: 'start', value: 'product_code', sortable: false },
      { text: 'Product Name', align: 'start', value: 'product_name', sortable: false },
      { text: 'Price', align: 'start', value: 'srp', sortable: false },
      { text: 'Qty', align: 'start', value: 'quantity', sortable: false },
    ])
    const headers = ref([])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const products = ref([])
    const product = ref('')
    const quantity = ref(null)
    const stocks = ref(0)
    const icon = {
      delete: mdiTrashCanOutline,
    }
    const error = ref(null)

    const { responseMessageStatus, responseMessage } = crud()

    headers.value = defaulHeader.value

    const getProducts = () => {
      get(`${process.env.VUE_APP_URI}/api/cart/products`, products, null)
    } 

    getProducts()

    const addItem = async () => {
      await get(`${process.env.VUE_APP_URI}/api/cart/${product.value}/available-stocks`, stocks, null, error)
      if(error.value !== null) {
        stocks.value = 0
      }

      if(quantity.value > 0) {
        if(parseInt(stocks.value) >= quantity.value) {
          const itemIndex = products.value.findIndex(val => val.id === product.value)
          const cartItemIndex = items.value.findIndex(val => val.product_id === product.value)

          if (cartItemIndex >= 0) {
            items.value[cartItemIndex].quantity = quantity.value
          } else {
            items.value.push({
              product_id: products.value[itemIndex].id,
              product_code: products.value[itemIndex].product_code,
              product_name: products.value[itemIndex].product_name,
              srp: props.customerType === 'WALKIN' ? products.value[itemIndex].srp : products.value[itemIndex][`${props.customerType.replace(" ", "_")}_price`],
              quantity: quantity.value,
            })
          }

          totalItems.value = items.value.length
          emit('data', items)
        } else {
          responseMessage.value = 'Insufficient stock.';
          responseMessageStatus.value = 'error';
        }
      }
    }

    const deleteItem = item => {
      const cartItemIndex = items.value.findIndex(val => val.product_id === item.product_id)
      items.value.splice(cartItemIndex, 1);
      emit('data', items)
    }

    watch(() => props.customerType, val => {
      if(items.value.length > 0) {
        items.value.forEach((element, index) => {
          const itemIndex = products.value.findIndex(val => val.id === element.product_id)
          items.value[index].srp = props.customerType === 'WALKIN' ? products.value[itemIndex].srp : products.value[itemIndex][`${props.customerType.replace(" ", "_")}_price`]
        })

        emit('data', items)
      }
    })

    watch(() => props.basket, val => {
      items.value = val
      totalItems.value = items.value.length
    })

    return {
      icon,
      selectedData,
      status,
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,
      products,
      product,
      quantity,
      addItem,
      deleteItem,
    }
  },

  components: {
    snackbar,
  },
}
</script>
